import React from 'react';
import { getCurrencyByDomain } from '@/utils/domain';
import Link from '../Link';
import './index.css';

const BENEFITS = [
  `Our gut test unravels the key biomarkers of your digestive, metabolic and brain health, with personalized suggestions on how to fix and optimize your gut health`,
];

export function SingleGutTestPricing() {
  function formatPrice(price: number, currency: string): string {
    if (currency === `CAD`) {
      const formattedPriceInCAD = Math.round(price * 1.4);
      return `${formattedPriceInCAD} CAD`; // Round to the nearest whole number
    }
    const formattedPriceInUSD = Math.round(price); // Round to the nearest whole number
    return `${formattedPriceInUSD} USD`; // Default to USD
  }

  const domain = typeof window !== `undefined` ? window.location.hostname : ``;
  const selectedCurrency = getCurrencyByDomain(domain);

  const stripeLinkUSD = `https://buy.stripe.com/7sI3gg9CJdVTgDK8wZ`;
  const stripeLinkCAD = `https://buy.stripe.com/aEUdUUdSZeZX9bi28z`;

  return (
    <div className="w-full bg-[#F7DEED] md:p-[24px] p-8 h-full md:mt[40px] slg:mt-0 md:pt-[48px] hover:shadow-[0px_-4px_8px_rgba(217,_185,_204,_0.2),_0px_4px_8px_rgba(217,_185,_204,_0.2)] hover:scale-[1.015] transition-all duration-[0.1s] ease-in-out group">
      <div className="h-[4px] rounded-[8px] bg-[#19233F]" />
      <h3 className="text-[24px] font-[500] mt-[20px] text-center">
        Single Gut Test
        <div>
          <span className="text-[30px] font-[700]">
            {formatPrice(150, selectedCurrency)}
          </span>
        </div>
      </h3>
      <div className="flex flex-col gap-y-[24px] mt-[36px]">
        {BENEFITS.map((b: string) => (
          <div key={b} className="flex items-start gap-x-[24px] h-fit">
            <img src="/check.svg" alt="" />
            <h4 className="text-[#19233F] text-[20px] -mt-2 leading-[30px] 3">
              {b}
            </h4>
          </div>
        ))}
      </div>

      {domain.includes(`.com`) && (
        <Link href={stripeLinkUSD}>
          <button
            type="button"
            className="mt-[40px] w-full border-[#19233F] border-solid border-[1px] rounded text-[#19233F] hover:bg-[#19233F] group-hover:bg-[#19233F] group-hover:text-[#FFFFFF] hover:text-[#FFFFFF] py-[18px] text-[20px] font-medium "
          >
            Choose Test
          </button>
        </Link>
      )}

      {(domain.includes(`.ca`) ||
        domain.includes(`vitract-ca.netlify.app/pricing/`)) && (
        <Link href={stripeLinkCAD}>
          <button
            type="button"
            // onClick={() => navigateToProduct(`single`, `Single Gut Test`)}
            className="mt-[40px] w-full border-[#19233F] border-solid border-[1px] rounded text-[#19233F] hover:bg-[#19233F] group-hover:bg-[#19233F] group-hover:text-[#FFFFFF] hover:text-[#FFFFFF] py-[18px] text-[20px] font-medium "
          >
            Choose Test
          </button>
        </Link>
      )}
    </div>
  );
}
